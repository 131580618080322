import PageTop from '@/components/PageTop';
import { LayoutProps } from '..';
import { useStyles } from '../styles';

export const DefaultPageLayout = ({ children, properties, image }: LayoutProps) => {
  const { classes } = useStyles();
  return (
    <>
      <PageTop image={image} properties={properties} />
      <section className={classes.main}>
        {children}
      </section>
    </>
  );
};

export default DefaultPageLayout;
