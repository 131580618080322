import { createStyles } from '@ingeniorforeningen/figurine-core';

export const useStyles = createStyles((theme) => ({
  root: {
    maxWidth: '86em !important', // this matches Figma Specs
    margin: '3rem auto 0 auto !important',
    
    '& div': {
      justifyContent: 'center !important',
      alignItems: 'center !important',
    },
    '& h1': {
      textAlign: 'center',
      lineHeight: '130.5%'
    },
    '& p': {
      textAlign: 'center',
      lineHeight: '143.5%',
      maxWidth: '58.625rem'
    },
    [theme.fn.largerThan('xs')]: {
      margin: '5.125rem auto !important',
    },
  },
}));

