import PageTop from '@/components/PageTop';
import { LayoutProps } from '.';
import StickyProgramAndSignup from '@/components/StickyProgramAndSignup';
import { useStyles } from '../styles';

export const InfoPageLayout = ({ children, properties, image }: LayoutProps) => {
  const { classes } = useStyles();
  return (
    <>
      <PageTop image={image} properties={properties} />

      <StickyProgramAndSignup
        isActive={properties?.isActive}
        program={properties?.programLink?.[0] as Record<string, any>}
        signup={properties?.signUpLink?.[0] as Record<string, any>}
      />
      
      <section className={classes.main}>
        {children}
      </section>
    </>
  );
};

export default InfoPageLayout;
