import { Hero } from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import { LayoutProps } from '.';
import { useStyles } from '../styles';
import StickyProgramAndSignup from '@/components/StickyProgramAndSignup';

const FrontPageLayout = (props: LayoutProps) => {
  const { properties, children } = props;
  const { classes, cx } = useStyles();

  return (
    <>
      <Hero data={props} variant="event" />
      <section className={cx(classes.main, classes.mainWithHero)}>{children}</section>
      <StickyProgramAndSignup
        isActive={properties?.isActive}
        program={properties?.programLink?.[0] as Record<string, any>}
        signup={properties?.signUpLink?.[0] as Record<string, any>}
        heroPresent
      />
    </>
  );
};

export default FrontPageLayout;
