import {
  Button,
  Floater,
  Grid,
  Heading,
  Modal,
  OnlyShow,
  Paper,
  Paragraph,
  showNotification,
} from '@ingeniorforeningen/figurine-core';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect, useState } from 'react';

const Preview = () => {
  const { locale } = useRouter();
  const [guid, setGuid] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = 'data-guid';
      setGuid(window.document.querySelector(`meta[${tag}]`)?.getAttribute(tag) as string);
    }
  }, []);

  const copyPreviewToClipBoard = (event: SyntheticEvent<Element, Event>) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      const previewUrl = `${window?.location?.origin}/api/preview?id=${guid}&locale=${locale}`;
      navigator.clipboard.writeText(previewUrl);
      showNotification({
        text: 'Side er gemt til udklipsholder',
        type: 'success',
      });
    }
  };

  const PreviewActions = (
    <>
      <Heading type="h3" text="Preview" color="light" />
      <Paragraph
        text="Du får vist upubliseret indhold, som ikke er tilgængeligt på sitet."
        color="light"
      />
      <Grid container>
        <Button
          href={`${process.env.NEXT_PUBLIC_UMBRACO_API_URL}/umbraco#/content/content/edit/${guid}`}
          color="brandSecondary"
          icon="Edit"
          text="Rediger side"
          target="_blank"
        />
        <Button
          onClick={copyPreviewToClipBoard}
          color="brandSecondary"
          text="Del side"
          icon="Send"
        />
        <Button
          href={`/api/exit-preview?guid=${guid}`}
          color="brandSecondary"
          text="Forlad preview"
          icon="DoorExit"
        />
      </Grid>
    </>
  );

  return (
    <>
      <OnlyShow largerThan={'lg'}>
        <Floater position={{ left: '75%', right: '5%', bottom: '40%' }}>
          <Paper>{PreviewActions}</Paper>
        </Floater>
      </OnlyShow>
      <OnlyShow smallerThan={'lg'}>
        <Floater position={{ bottom: '20%', right: '10%' }}>
          <Button text="Preview" icon="Eye" onClick={() => setShowModal(!showModal)} />
          <Modal opened={showModal} onClose={() => setShowModal(false)}>
            {PreviewActions}
          </Modal>
        </Floater>
      </OnlyShow>
    </>
  );
};

export default Preview;
