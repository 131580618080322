import { createStyles } from '@ingeniorforeningen/figurine-core';
import bg from '../../public/assets/images/preview.png';

export const useStyles = createStyles((theme) => ({
  main: {
    maxWidth: '86em', // this matches Figma Specs
    margin: '2rem auto',
    [theme.fn.largerThan('xs')]: {
      margin: '0 auto 5.125rem auto',
    },
  },
  mainWithHero: {
    marginTop: '2rem !important',
    [theme.fn.largerThan('xs')]: {
      marginTop: '3.75rem !important',
    }
  },
  preview: {
    backgroundImage: `url(${bg.src})`,
    position: 'fixed',
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  floaterInner: {
    position: 'sticky',
    top: '5em',
  },
}));

